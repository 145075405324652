<template>
    <div v-if="isReady">
        <CarouselSponsoredProductsComponent v-if="sponsoredProductSlides && sponsoredProductSlides.length" :slides="sponsoredProductSlides" :randomize="content.sponsoredProductSlidesRandomize"></CarouselSponsoredProductsComponent>

        <div class="extra-wide">
            <div class="slab py-3 py-lg-6 mt-0 mb-6">
                <div class="container px-lg-0 text-18">
                    <html-blob-component v-if="htmlBlobContent.length && htmlBlobContent[0].content && htmlBlobContent[0].content[0].content" :html="htmlBlobContent[0].content[0].content"></html-blob-component>
                    <component :is="componentMap[searchComponent]" v-if="componentMap[searchComponent]" :brands="brands"></component>
                </div>
            </div>
        </div>

        <section v-if="themedArticles.length" class="container px-lg-0 pb-0 my-4">
            <h2 class="text--serif mb-2" v-html="themedArticlesHeader"></h2>
            <content-tiles-component :content-tiles="themedArticles" :layout-class="(themedArticles.length % 3 === 0) ? '-tall -featured -threecols' : '-tall -featured -twocols'" :lazy-load="true"></content-tiles-component>
        </section>

        <div class="slab py-6 mb-6">
            <div v-if="htmlBlobContent.length && htmlBlobContent[1].content" class="container px-lg-0">
                <html-blob-component v-for="(blob, index) in htmlBlobContent[1].content" :key="index" :html="blob.content"></html-blob-component>
            </div>
        </div>

        <section v-if="brandsWithExtras.length" class="container px-lg-0 pb-0 my-4" data-testid="brand-extra-links">
            <h2 class="text--serif mb-2" v-html="brandsHeader"></h2>
            <box-links-component class="text-links-grid -compact weglot-exclude" :content="brandsWithExtras" link-class="-neutral" @box-link-click="trackingBoxLinkBrowseClickHandler"></box-links-component>
        </section>

        <div class="ad-zone">
            <broadstreet-zone zone-id="82344"></broadstreet-zone>
        </div>

        <section v-if="categoryCards.length || buttonLinks.length" class="container px-lg-0 pb-0 my-4">
            <h2 class="text--serif mb-2" v-html="categoryCardsHeader"></h2>
            <feature-cards-component v-if="categoryCards.length" class="destination-catalogs" :content="categoryCards" :page-name="pageName" @feature-card-click="trackFeatureCardBrowseClickHandler"></feature-cards-component>
            <box-links-component v-if="buttonLinks.length" :class="['text-links-grid', (buttonLinks.length < 3) ? '-two-cols' : '']" :content="buttonLinks" link-class="-neutral" @box-link-click="trackingBoxLinkBrowseClickHandler"></box-links-component>
        </section>

        <div class="ad-zone">
            <broadstreet-zone zone-id="78463"></broadstreet-zone>
        </div>

        <section class="my-4">
            <div class="container px-lg-0">
                <article-cards-component :articles-per-page="3" :event-start-index="4" :header-text="content.featuredArticlesHeader" :initial-cards="content.featuredArticles" :lazy-load="true" :page-name="pageName" :search-criteria="content.articleSearchCriteria" :show-more="true"></article-cards-component>
            </div>
        </section>

        <section v-if="content.realTravelerStoriesCarouselContent && content.realTravelerStoriesCarouselContent.slides && content.realTravelerStoriesCarouselContent.slides.length > 1" :id="'rts-carousel-' + Date.now()" :class="['py-0 my-3', content.realTravelerStoriesContainerClasses]">
            <real-traveler-stories-carousel-component :content="content.realTravelerStoriesCarouselContent"></real-traveler-stories-carousel-component>
        </section>
        <find-an-advisor-cta-component v-else></find-an-advisor-cta-component>

        <section v-if="content.featuredDestinations.length" class="container px-lg-0 pb-0 my-4">
            <h2 class="text--serif mb-2" v-html="content.featuredDestinationsHeader"></h2>
            <feature-cards-component class="featured-destinations" :content="content.featuredDestinations" @feature-card-clicked="trackFeatureCardDestination"></feature-cards-component>
        </section>

        <product-cards-component v-if="content.productCardCollection.productCards.length" id="featured-products" :content="content.productCardCollection" class="py-6"></product-cards-component>
    </div>
    <LogoSplash v-else />
</template>


<script setup lang="ts">
    import SearchFormCruisesComponent from "components/products/search/search-form-cruises.vue";
    import SearchFormHotelsComponent from "components/products/search/search-form-hotels.vue";
    import SearchFormToursComponent from "components/products/search/search-form-tours.vue";
    import FindAnAdvisorCtaComponent from "components/advisor/find-an-advisor-cta.vue";
    import ArticleCardsComponent from "components/article/article-cards.vue";
    import ProductCardsComponent from "components/products/product-cards.vue";
    import BoxLinksComponent from "components/shared/box-links.vue";
    import CarouselSponsoredProductsComponent from "components/shared/carousel-sponsored-products.vue";
    import ContentTilesComponent from "components/shared/content-tiles.vue";
    import FeatureCardsComponent from "components/shared/feature-cards.vue";
    import HtmlBlobComponent from "components/shared/html-blob-allow-side-effects.vue";
    import LogoSplash from "components/shared/logo-splash.vue";
    import RealTravelerStoriesCarouselComponent from "components/shared/real-traveler-stories-carousel.vue";
    import { tourBrandSupplierTypesRestricted } from "config/collections";
    import { DotCMSBasicArticleResponse, DotCMSDestinationsResponse, DotCMSProductLandingPageResponse, DotCMSRealTravelerStories } from "interfaces/responses/dotcms-responses";
    import { BrandsResponse } from "interfaces/responses/product-detail-responses";
    import { ContentTile, FeatureCard, ProductCardSearchResult } from "interfaces/card";
    import { CarouselSlide } from "interfaces/carousel";
    import { CmsPage, CmsPageContainer } from "interfaces/cms";
    import { ProductType } from "interfaces/enums";
    import { BoxLink } from "interfaces/link";
    import { ProductBrandsRequestPayload, ProductLandingPageContent } from "interfaces/product";
    import { getLandingPageJSON, getProductBrands, getProductLandingJSON } from "services/api/products";
    import { transformDotCMSPageContainers } from "services/cms/cms";
    import { buildDestinationPageURL } from "services/helpers/destinations";
    import { generateCmsImageUrl } from "services/helpers/images";
    import { toastError } from "services/helpers/toasts";
    import { transformCmsBasicArticleToArticleCard, transformCmsBasicArticleToContentTile } from "services/transformers/article";
    import { transformCmsFeatureCarouselSlideToCarouselSlide, transformRTSCarouselContent } from "services/transformers/content-transformers";
    import { trackEvent } from "services/analytics";
    import { getSponsoredAndRandomProducts, getViewAllLabel } from "services/product-cards";
    import * as virtuosoSharedHeader from "virtuoso-shared-web-ui";
    import type { Component } from "vue";
    import { nextTick, ref, watch } from "vue";

    const qsParams = virtuosoSharedHeader.parseURLParameters();
    const isReady = ref(false);

    const brands = ref<BoxLink[]>([] as BoxLink[]);
    const brandsWithExtras = ref<BoxLink[]>([] as BoxLink[]);
    const brandsHeader = ref("");
    const category = window.VIRTUOSO.productType;
    const content = ref<ProductLandingPageContent>({} as ProductLandingPageContent);
    const pageName = ref("");
    const searchComponent = ref("");
    const sponsoredProductSlides = ref<CarouselSlide[]>([] as CarouselSlide[]);
    const htmlBlobContent = ref<CmsPageContainer<CmsPageContainer<string>[]>[]>([] as CmsPageContainer<CmsPageContainer<string>[]>[]);
    const customCompanies = ref<BoxLink[]>([] as BoxLink[]);
    const themedArticlesHeader = ref("");
    const themedArticles = ref<ContentTile[]>([] as ContentTile[]);
    const categoryCardsHeader = ref("");
    const categoryCards = ref<FeatureCard[]>([] as FeatureCard[]);
    const buttonLinks = ref<BoxLink[]>([] as BoxLink[]);
    const brandTrackMEIDs = ref<BrandsResponse[]>([] as BrandsResponse[]);
    // methods
    const populateBrands = (productType: ProductType, brandBaseUrl: string, postParams?: ProductBrandsRequestPayload): void => {
        getProductBrands(productType, postParams).then((result: BrandsResponse[]) => {
            if (result && result.length) {
                brandTrackMEIDs.value = result;
                result.forEach((obj: BrandsResponse) => {
                    (brands.value as BoxLink[]).push({
                        name: obj.supplierName,
                        url: virtuosoSharedHeader.cobrandLink(`${brandBaseUrl}${obj.supplierId}/${virtuosoSharedHeader.slugify(obj.supplierName)}`)
                    });
                });
            }

            (brands.value).sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));

            // Add extra brands from dotCMS, if present
            // Note that we don't want to display only the dotCMS ones if the main call failed,
            // which is why this lives in this block
            brandsWithExtras.value = [...brands.value];
            if (customCompanies.value) { // Any extras from dotCMS
                customCompanies.value.forEach((item: BoxLink) => {
                    (brandsWithExtras.value as BoxLink[]).push({
                        name: item.name,
                        url: item.url
                    });
                });

                (brandsWithExtras.value).sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
            }

        }, () => {
            // If this dies, we don't need to do anything additional
        });
    };

    // Map of component names to actual components
    const componentMap: Record<string, Component> = {
        "search-form-cruises-component": SearchFormCruisesComponent,
        "search-form-hotels-component": SearchFormHotelsComponent,
        "search-form-tours-component": SearchFormToursComponent
    };

    const trackingBoxLinkBrowseClickHandler = (boxlink: BoxLink): void => {
        const shouldTrackMEID = (category === "cruises" || category === "tours");
        const brandMeid = brandTrackMEIDs.value.find((brand) => brand.supplierName === boxlink.name);
        const trackedMEID = (brandMeid && brandMeid.supplierId) ? brandMeid.supplierId : "";

        trackEvent("browse_click", {
            item_name: boxlink.name,
            item_category: category,
            item_variant: "text",
            ...(shouldTrackMEID && { item_id: trackedMEID })
        });
    };
    const trackFeatureCardBrowseClickHandler = (featuredCard: FeatureCard): void => {
        trackEvent("browse_click", {
            item_name: featuredCard.name,
            item_category: category,
            item_variant: "image",
            item_id: ""
        });
    };
    const trackFeatureCardDestination = (featuredCard: FeatureCard): void => {
        trackEvent("select_item", {
            item_id: featuredCard.url,
            item_name: featuredCard.name,
            item_category: "Destination"
        });
    };
    // logic
    if (category) {
        // logic - entryPoint
        let cmsPage: CmsPage = {
            containers: [],
            title: ""
        };
        getLandingPageJSON(category).then(async (landingPageJSON) => {
            if (landingPageJSON.entity && landingPageJSON.entity.containers) {
                cmsPage = transformDotCMSPageContainers(landingPageJSON);
            } else {
                console.error("Error retrieving data, no containers found: ", landingPageJSON);
            }

            // ######### Render Mounted Logic
            pageName.value = `${category} Landing Page`;
            searchComponent.value = `search-form-${category}-component`;
            if (category === ProductType.CRUISES) {

                brandsHeader.value = "Browse Cruise Lines";
                populateBrands(category, "/travel/luxury-cruises/cruise-lines/");

            } else if (category === ProductType.TOURS) {

                brandsHeader.value = "Browse Tour Operators";
                populateBrands(category, "/travel/luxury-tours/tour-operators/", {
                    supplierTypes: tourBrandSupplierTypesRestricted
                });

            }
            // Initialize and populate the content objects

            content.value.productType = category;
            htmlBlobContent.value = (cmsPage.containers).filter((container) => container.friendlyName === "html-blob") as CmsPageContainer<CmsPageContainer<string>[]>[];

            content.value.featuredArticlesHeader = "More Stories";
            content.value.featuredArticles = [];
            content.value.articleSearchCriteria = {
                contentTypes: ["ConsumerArticleBasic"],
                queryClauses: [
                    `+(categories:${category})`
                ],
                sort: "ConsumerArticleBasic.publish desc"
            };

            content.value.productCardCollection = {
                productCards: [],
                productType: category
            };

            const realTravelerStoriesContainers: CmsPageContainer<DotCMSRealTravelerStories[]>[] = (cmsPage.containers).filter((container) => container.friendlyName === "real-traveler-stories-carousel") as CmsPageContainer<DotCMSRealTravelerStories[]>[];
            content.value.realTravelerStoriesContainerClasses = "";
            content.value.featuredDestinationsHeader = "More Stories";
            content.value.featuredDestinations = [];

            // Get the associated content for this product type -- has to be a separate call because of the related content
            const productLandingPageContent: CmsPageContainer<ProductLandingPageContent[]>[] = (cmsPage.containers).filter((container) => container.friendlyName === "product-landing-page") as CmsPageContainer<ProductLandingPageContent[]>[];

            if (productLandingPageContent.length && productLandingPageContent[0].content?.length && productLandingPageContent[0].content[0].identifier) {

                getProductLandingJSON(productLandingPageContent[0].content[0].identifier).then((productLandingJSON) => {
                    if (productLandingJSON && productLandingJSON.contentlets?.length > 0) {

                        const productLandingContent: DotCMSProductLandingPageResponse = productLandingJSON.contentlets[0];

                        // Sponsored products carousel
                        if (productLandingContent.sponsoredCarouselSlides?.length > 0) {
                            productLandingContent.sponsoredCarouselSlides.forEach((slide) => {
                                const transformedSlide = transformCmsFeatureCarouselSlideToCarouselSlide(slide);
                                if (transformedSlide) {
                                    sponsoredProductSlides.value.push(transformedSlide);
                                }
                            });
                        }

                        content.value.sponsoredProductSlidesRandomize = (productLandingContent.slideOrder === "random") ? true : false;


                        // Themed articles
                        if (productLandingContent.themedArticles?.length > 0) {
                            themedArticlesHeader.value = productLandingContent.themedArticlesHeader;

                            (productLandingContent.themedArticles).forEach((article: DotCMSBasicArticleResponse) => {
                                themedArticles.value.push(transformCmsBasicArticleToContentTile(article));
                            });
                        }


                        // Category Cards
                        categoryCardsHeader.value = productLandingContent.categoryCardsHeader;

                        for (let i = 1; i < 5; i++) { 
                            const categoryCardImage = productLandingContent[`categoryCard${i}Image` as keyof DotCMSProductLandingPageResponse] as string;
                            const categoryCardLink = productLandingContent[`categoryCard${i}Link` as keyof DotCMSProductLandingPageResponse] as string;
                            const categoryCardText = productLandingContent[`categoryCard${i}Text` as keyof DotCMSProductLandingPageResponse] as string;                            

                            if (categoryCardImage && categoryCardText && categoryCardLink) {
                                categoryCards.value.push({
                                    imageUrl: generateCmsImageUrl(categoryCardImage),
                                    name: categoryCardText,
                                    url: virtuosoSharedHeader.cobrandLink(categoryCardLink)
                                });
                            }
                        }


                        // Content Button links
                        for (let linkIndex = 1; linkIndex <= 4; linkIndex++) {
                            const categoryButtonLink = productLandingContent[`categoryButton${linkIndex}Link` as keyof DotCMSProductLandingPageResponse] as string;
                            const categoryButtonText = productLandingContent[`categoryButton${linkIndex}Text` as keyof DotCMSProductLandingPageResponse] as string;

                            if (categoryButtonText && categoryButtonLink) {
                                buttonLinks.value.push({
                                    name: categoryButtonText,
                                    url: virtuosoSharedHeader.cobrandLink(categoryButtonLink)
                                });
                            }
                        }


                        // Featured Articles
                        if (productLandingContent.featuredArticlesHeader && productLandingContent.featuredArticlesHeader !== "") {
                            content.value.featuredArticlesHeader = productLandingContent.featuredArticlesHeader;
                        }

                        if (productLandingContent.featuredArticles && productLandingContent.featuredArticles.length) {
                            (productLandingContent.featuredArticles).forEach((article: DotCMSBasicArticleResponse) => {
                                content.value.featuredArticles.push(transformCmsBasicArticleToArticleCard(article));
                            });
                        }


                        // Real Traveler Stories Carousel
                        if (realTravelerStoriesContainers.length && realTravelerStoriesContainers[0].content?.length) {
                            content.value.realTravelerStoriesCarouselContent = transformRTSCarouselContent(realTravelerStoriesContainers[0].content[0]);
                            content.value.realTravelerStoriesContainerClasses = realTravelerStoriesContainers[0].containerClasses;
                        }


                        // Featured Destinations
                        // TODO: Only populate if there are exactly 5???
                        if (productLandingContent.featuredDestinationsHeader && productLandingContent.featuredDestinationsHeader !== "") {
                            content.value.featuredDestinationsHeader = productLandingContent.featuredDestinationsHeader;
                        }

                        (productLandingContent.featuredDestinations).forEach((featuredDest: DotCMSDestinationsResponse) => {
                            content.value.featuredDestinations.push({
                                imageUrl: generateCmsImageUrl(featuredDest.gridImage),
                                url: buildDestinationPageURL(featuredDest.destinationTag, featuredDest.isArbitraryDestination),
                                name: featuredDest.title
                            });
                        });


                        // Custom companies (for brand link grid)
                        if (productLandingContent.customCompanies) {
                            const customCompany = productLandingContent.customCompanies.split("|");
                            customCompany.forEach((company) => {
                                const companyFields = company.split("~");
                                if (companyFields.length === 2) {
                                    customCompanies.value.push({
                                        name: companyFields[0],
                                        url: virtuosoSharedHeader.cobrandLink((category === ProductType.CRUISES)
                                            ? "/travel/luxury-cruises/cruise-lines/"
                                            : "/travel/luxury-tours/tour-operators/")
                                            + `${companyFields[1]}/${virtuosoSharedHeader.slugify(companyFields[0])}`
                                    });
                                }
                            });
                        }


                        // Product cards -- sponsored + random
                        if (category === ProductType.CRUISES) {
                            content.value.productCardCollection.header = "Set Sail: Virtuoso Cruises";
                            content.value.productCardCollection.viewAllLink = virtuosoSharedHeader.cobrandLink("/travel/luxury-cruises/search");
                            content.value.productCardCollection.viewAllText = "See More Cruises";
                        } else if (category === ProductType.TOURS) {
                            content.value.productCardCollection.header = "Let&rsquo;s Go: Virtuoso Tours & Experiences";
                            content.value.productCardCollection.viewAllLink = virtuosoSharedHeader.cobrandLink("/travel/luxury-tours/search");
                            content.value.productCardCollection.viewAllText = "See More Tours & Experiences";
                        } else { // Hotels
                            content.value.productCardCollection.header = "Check In: Virtuoso Hotels";
                            content.value.productCardCollection.viewAllLink = virtuosoSharedHeader.cobrandLink("/travel/luxury-hotels/search");
                            content.value.productCardCollection.viewAllText = "See More Hotels";
                        }

                        // This is async and will return after the initial render happens
                        getSponsoredAndRandomProducts(category, { rowsLimit: 6 }, productLandingContent.sponsoredProductIds).then(async (prods: ProductCardSearchResult) => {
                            content.value.productCardCollection.productCards = await prods.productCards;
                            if (category === ProductType.CRUISES) {
                                content.value.productCardCollection.viewAllText = getViewAllLabel(category, prods.totalResults, 6);
                            } else if (category === ProductType.TOURS) {
                                content.value.productCardCollection.viewAllText = getViewAllLabel(category, prods.totalResults, 6);
                            } else { // Hotels
                                content.value.productCardCollection.viewAllText = getViewAllLabel(ProductType.HOTELS, prods.totalResults, 6);
                            }

                        });

                        // watch
                        const contentVal = content.value;
                        const productCardCollection = ref(contentVal.productCardCollection);
                        const productCardCollectionVal = productCardCollection.value;
                        const productCards = ref(productCardCollectionVal.productCards.length);

                        watch(productCards, (newVal: number): void => {
                            if (qsParams["featured-products"] === "1") {
                                if (newVal > 0) {
                                    nextTick(() => {
                                        document.getElementById("featured-products")?.scrollIntoView();
                                    });
                                }
                            }
                        });
                    }
                }, () => {
                    toastError("Error retrieving data");
                }).finally(() => {
                    isReady.value = true;
                });
            }

        }, (e) => {
            toastError("Error retrieving data");
            console.error(`Error retrieving data for /${category}-landing-page: `, e);
        });

    } else {
        console.error("Product type not provided");
    }

</script>
