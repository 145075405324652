<template>
    <div class="mt-3" data-testid="cruise-search">
        <div class="row">
            <div class="-query-container col-12 col-lg-4">
                <label class="select--styled mb-0">
                    <select v-model="cruiseLines" name="cruiseLines" aria-label="Cruise Lines">
                        <option value="">Any Cruise Line</option>
                        <option v-for="(brand, brandIndex) in brands" :key="brandIndex" :value="brand.name" v-html="brand.name"></option>
                    </select>
                </label>
            </div>
            <div class="col-12 col-lg-3 mt-1 mt-lg-0">
                <label class="select--styled mb-0">
                    <select v-model="destination" name="destination" aria-label="destination">
                        <option value="">Any Destination</option>
                        <option value="Africa &amp; Arabian Peninsula">Africa &amp; Arabian Peninsula</option>
                        <option value="Alaska &amp; Pacific Northwest">Alaska &amp; Pacific Northwest</option>
                        <option value="Asia">Asia</option>
                        <option value="Canada &amp; New England">Canada &amp; New England</option>
                        <option value="Caribbean">Caribbean</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Mediterranean &amp; Southern Europe">Mediterranean &amp; Southern Europe</option>
                        <option value="Mexico &amp; Central America">Mexico &amp; Central America</option>
                        <option value="Northern Europe &amp; the British Isles">Northern Europe &amp; the British Isles</option>
                        <option value="South America &amp; Antarctica">South America &amp; Antarctica</option>
                        <option value="South Pacific">South Pacific</option>
                        <option value="Western &amp; Central Europe">Western &amp; Central Europe</option>
                    </select>
                </label>
            </div>
            <div class="col-12 col-lg-3 mt-1 mt-lg-0">
                <date-picker-component id="filter-dates" placeholder-text="Travel Dates"></date-picker-component>
            </div>
            <div class="col-2 mt-0 d-none d-lg-block">
                <button class="btn btn-primary-emphasis btn-sm js-search-button w-100" @click="doSearch">Search</button>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12 col-lg-2">
                <button class="btn btn-xs btn-tertiary" @click="toggleMoreFilters"><i :class="(isMoreFiltersOpen) ? 'icon-angle-down-ut' : 'icon-angle-right-ut'"></i> More filters</button>
            </div>
            <div id="more-filters-panel" class="col-12 col-lg-10 text--small">
                <div class="row">
                    <div class="col-6 col-md-3 col-lg-2">
                        <span class="text-emphasis font-weight-bold">Duration</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in durationFacets" :key="facetIndex">
                                <label>
                                    <input :id="'lengths-' + facet" :key="'lengths-' + facet" v-model="selectedLengths" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2">
                        <span class="text-emphasis font-weight-bold">Style</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in styleFacets" :key="facetIndex">
                                <label>
                                    <input :id="'cruiseTypes-' + facet" :key="'cruiseTypes-' + facet" v-model="selectedCruiseTypes" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <span class="text-emphasis font-weight-bold">Virtuoso Exclusives</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in exclusivesFacets" :key="facetIndex">
                                <label>
                                    <input :id="'exclusives-' + facet" :key="'exclusives-' + facet" v-model="selectedExclusives" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 col-lg-5">
                        <span class="text-emphasis font-weight-bold">Keyword</span>
                        <AutocompleteInput v-model="query" :show-icon="false" :show-mobile-button="false" :type="ProductType.CRUISES" placeholder-text="Keyword" @search="doSearch" @select="selectCategory" @clear="() => { query = '' }" />
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2 d-lg-none">
                <button class="btn btn-primary-emphasis btn-sm js-search-button w-100" @click="doSearch">Search</button>
            </div>
        </div>
        <div class="mt-2 text--small fw-bold d-none d-lg-block">
            <a :href="b2bCatalogLink" v-html="legacyLinkText"></a>
        </div>
    </div>
</template>


<script setup lang="ts">
    import AutocompleteInput from "components/shared/autocomplete-input.vue";
    import DatePickerComponent from "components/shared/date-picker.vue";
    import { facetsByProduct } from "config/search-facet-data";
    import { ProductType } from "interfaces/enums";
    import { BoxLink } from "interfaces/link";
    import { ProductSearchConfig } from "interfaces/product";
    import { isSupplier } from "services/auth/user-info";
    import { slideDownWithFade, slideUpWithFade } from "services/helpers/html";
    import { trackOmniboxSearch } from "services/analytics";
    import { cobrandLink } from "virtuoso-shared-web-ui";
    import { PropType, ref } from "vue";

    let omniboxCategorySearch = "";

    defineProps({
        brands: {
            type: Array as PropType<BoxLink[]>,
            default: undefined
        }
    });

    const b2bCatalogLink = cobrandLink("/cruises");
    const cruiseLines = ref("");
    const destination = ref("");
    const durationFacets = ref(facetsByProduct[ProductType.CRUISES].find((category: ProductSearchConfig) => category.categoryName === "lengths")?.facets);
    const exclusivesFacets = ref(facetsByProduct[ProductType.CRUISES].find((category: ProductSearchConfig) => category.categoryName === "virtuosoExclusives")?.facets);
    const isMoreFiltersOpen = ref(false);
    const legacyLinkText = `For ${(isSupplier()) ? "Partners" : "Advisors"}: Advanced Search`;
    const query = ref("");
    const selectedCruiseTypes = ref([]);
    const selectedExclusives = ref([]);
    const selectedLengths = ref([]);
    const styleFacets = ref(facetsByProduct[ProductType.CRUISES].find((category: ProductSearchConfig) => category.categoryName === "cruiseTypes")?.facets);

    function doSearch(): void {
        let queryHash = "#";
        // Omnibox Search
        if (omniboxCategorySearch !== "") {
            queryHash += `${encodeURIComponent(omniboxCategorySearch.trim())}=${encodeURIComponent(query.value.trim())}&`;
        }
        // Text query
        if (query.value.trim() !== "" && omniboxCategorySearch === "") {
            queryHash += `query=${encodeURIComponent(query.value.trim())}&sort=SearchRelevance&`;
        }
        // Destination
        if (destination.value !== "") {
            queryHash += `destinations=${encodeURIComponent(destination.value)}&`;
        }
        // Cruise Line
        if (cruiseLines.value !== "") {
            queryHash += `cruiseLines=${encodeURIComponent(cruiseLines.value)}&`;
        }
        // Travel dates (they'll either both be populated or neither)
        const startDate = (document.getElementById("dp-start-date") as HTMLInputElement).value;
        const endDate = (document.getElementById("dp-end-date") as HTMLInputElement).value;

        if (startDate !== "" && endDate !== "") {
            queryHash += `startDate=${startDate}&endDate=${endDate}&`;
        }
        // Cruise length
        if (selectedLengths.value.length > 0) {
            queryHash += `lengths=${selectedLengths.value.join("|")}&`;
        }
        // Cruise Type
        if (selectedCruiseTypes.value.length > 0) {
            queryHash += `cruiseTypes=${selectedCruiseTypes.value.join("|")}&`;
        }
        // Virtuoso Exclusives
        if (selectedExclusives.value.length > 0) {
            queryHash += `virtuosoExclusives=${selectedExclusives.value.join("|")}&`;
        }
        // Trim trailing ampersand
        if (queryHash.slice(-1) === "&") {
            queryHash = queryHash.slice(0, -1); 
        }

        window.location.href = cobrandLink(`/travel/luxury-cruises/search${queryHash}`);
    }

    function selectCategory(acCategory: string, acValue: string): void {
        query.value = acValue;

        switch (acCategory) {
            case "city":
                omniboxCategorySearch = "citySearch";
                break;
            case "country":
                omniboxCategorySearch = "countrySearch";
                break;
            case "cruise line name":
                omniboxCategorySearch = "companySearch";
                break;
            case "all fields":
                omniboxCategorySearch = "";
                break;
        }

        trackOmniboxSearch(ProductType.CRUISES, acCategory, acValue);

        doSearch();
    }

    function toggleMoreFilters(): void {
        if (isMoreFiltersOpen.value) {
            slideUpWithFade(document.getElementById("more-filters-panel"));
        } else {
            slideDownWithFade(document.getElementById("more-filters-panel"));
        }
        isMoreFiltersOpen.value = !isMoreFiltersOpen.value;
    }    
</script>
