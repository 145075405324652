<template>
    <div class="mt-3" data-testid="hotel-search">
        <div class="row">
            <div class="col-12 col-md-9 col-lg-6 mb-3 mb-md-0">                
                <AutocompleteInput v-model="query" :show-icon="false" :show-mobile-button="false" :type="ProductType.HOTELS" placeholder-text="Where do you want to go?" @search="doSearch" @select="selectCategory" @clear="() => { query = '' }" />
            </div>
            <div class="col-12 col-md-3 col-lg-2">
                <button class="btn btn-primary-emphasis btn-sm w-100" @click="doSearch()">Search</button>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12 col-lg-2">
                <button class="btn btn-xs btn-tertiary" @click="toggleMoreFilters"><i :class="(isMoreFiltersOpen) ? 'icon-angle-down-ut' : 'icon-angle-right-ut'"></i> More filters</button>
            </div>
            <div id="more-filters-panel" class="col-12 col-lg-10 text--small">
                <div class="row">
                    <div class="col-12 col-md-4 col-lg-3 ">
                        <span class="text-emphasis font-weight-bold">Hotel Type</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in hotelTypeFacets" :key="facetIndex">
                                <label>
                                    <input :id="'hotelTypes-' + facet" :key="'hotelTypes-' + facet" v-model="selectedHotelTypes" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-8 col-lg-6">
                        <span class="text-emphasis font-weight-bold">Experiences</span>
                        <ul id="experiences-container" class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in experienceFacets" :key="facetIndex">
                                <label>
                                    <input :id="'experiences-' + facet" :key="'experiences-' + facet" v-model="selectedExperiences" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <span class="text-emphasis font-weight-bold">Virtuoso Exclusives</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in exclusivesFacets" :key="facetIndex">
                                <label>
                                    <input :id="'exclusives-' + facet" :key="'exclusives-' + facet" v-model="selectedExclusives" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>

                        <span class="text-emphasis font-weight-bold mt-lg-1">Virtuoso Sustainability</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in sustainabilityFacets" :key="facetIndex">
                                <label>
                                    <input :id="'sustainability-' + facet" :key="'sustainability-' + facet" v-model="selectedSustainability" type="checkbox" :value="true"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-1 d-lg-none">
                        <button class="btn btn-primary-emphasis btn-sm js-search-button w-100" @click="doSearch()">Search</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2 text--small fw-bold d-none d-lg-block">
            <a :href="b2bCatalogLink">Find rates and availability here.</a>
        </div>
    </div>
</template>


<script setup lang="ts">
    import AutocompleteInput from "components/shared/autocomplete-input.vue";
    import { facetsByProduct } from "config/search-facet-data";
    import { ProductType } from "interfaces/enums";
    import { ProductSearchConfig } from "interfaces/product";
    import { slideDownWithFade, slideUpWithFade } from "services/helpers/html";
    import { trackOmniboxSearch } from "services/analytics";
    import { cobrandLink } from "virtuoso-shared-web-ui";
    import { ref } from "vue";

    let omniboxCategorySearch = "";
    const catalogType: ProductType = ProductType.HOTELS;

    const b2bCatalogLink = cobrandLink("/hotels");  
    const exclusivesFacets = ref(facetsByProduct[catalogType].find((category: ProductSearchConfig) => category.categoryName === "virtuosoExclusives")?.facets);
    const experienceFacets = ref(facetsByProduct[catalogType].find((category: ProductSearchConfig) => category.categoryName === "experiences")?.facets);   
    const hotelTypeFacets = ref(facetsByProduct[catalogType].find((category: ProductSearchConfig) => category.categoryName === "hotelTypes")?.facets);    
    const isMoreFiltersOpen = ref(false);
    const query = ref("");
    const selectedExclusives = ref([]);
    const selectedExperiences = ref([]);
    const selectedHotelTypes = ref([]);
    const selectedSustainability = ref(null);
    const sustainabilityFacets = ref(facetsByProduct[catalogType].find((category: ProductSearchConfig) => category.categoryName === "sustainability")?.facets);

    function doSearch(): void {
        let queryHash = "#";
        // Omnibox Search
        if (omniboxCategorySearch !== "") {
            queryHash += `${encodeURIComponent(omniboxCategorySearch.trim())}=${encodeURIComponent(query.value.trim())}&`;
        }
        // Text query
        if (query.value.trim() !== "" && omniboxCategorySearch === "") {
            queryHash += `query=${encodeURIComponent(query.value.trim())}&sort=SearchRelevance&`;
        }
        // Virtuoso Exclusives
        if (selectedExclusives.value.length > 0) {
            queryHash += `virtuosoExclusives=${selectedExclusives.value.join("|")}&`;
        }
        // Virtuoso Sustainability
        if (selectedSustainability.value) {
            queryHash += "sustainability=true&";
        }
        // Experiences
        if (selectedExperiences.value.length > 0) {
            queryHash += `experiences=${selectedExperiences.value.join("|")}&`;
        }
        // Hotel Type
        if (selectedHotelTypes.value.length > 0) {
            queryHash += `hotelTypes=${selectedHotelTypes.value.join("|")}&`;
        }

        window.location.href = cobrandLink(`/travel/luxury-hotels/search${queryHash}`);
    }

    function selectCategory(acCategory: string, acValue: string): void {
        query.value = acValue;

        switch (acCategory) {
            case "city":
                omniboxCategorySearch = "citySearch";
                break;
            case "country":
                omniboxCategorySearch = "countrySearch";
                break;
            case "hotel name":
                omniboxCategorySearch = "companySearch";
                break;
            case "all fields":
                omniboxCategorySearch = "";
                break;
        }

        trackOmniboxSearch(ProductType.HOTELS, acCategory, acValue);

        doSearch();
    }

    function toggleMoreFilters(): void {
        if (isMoreFiltersOpen.value) {
            slideUpWithFade(document.getElementById("more-filters-panel"));
        } else {
            slideDownWithFade(document.getElementById("more-filters-panel"));
        }
        isMoreFiltersOpen.value = !isMoreFiltersOpen.value;
    }  
</script>
