<template>
    <CarouselSliderComponent
        carousel-class="extra-wide sponsored-product-carousel"
        :carousel-config="carouselConfig"
        :should-enable-hearts="true"
        :slides="slides"
        @ready="() => logAdView(0)"
        @moved="(currentSlideIndex: number) => logAdView(currentSlideIndex)">
        <template #slides="{index, slide, instance}">                
            <div class="slide">
                <button v-if="slide.identifier && slide.heartType"
                        title="Save to WanderList"
                        class="wl-heartable -top-right d-lg-none"
                        :data-wl-type="slide.heartType"
                        :data-wl-id="slide.identifier"
                        :data-wl-title="slide.title"></button>
                <button v-if="slide.identifier && slide.heartType"
                        title="Save to WanderList"
                        class="wl-heartable -save-this -top-right d-none d-lg-block"
                        :data-wl-type="slide.heartType"
                        :data-wl-id="slide.identifier"
                        :data-wl-title="slide.title"></button>
                <a :href="slide.url">
                    <div class="title-block">
                        <div v-if="slide.slideType === 'hotel'"
                             class="-bob">
                            <div class="-boblogo">
                                <img src="https://virtuoso-prod.dotcms.cloud/images/products/bob-logo-gray.svg"
                                     alt="Virtuoso Best of the Best logo"
                                     title="Virtuoso Best of the Best"
                                     class="d-lg-none" />
                                <img src="https://virtuoso-prod.dotcms.cloud/images/products/bob-logo.svg"
                                     alt="Virtuoso Best of the Best logo"
                                     title="Virtuoso Best of the Best"
                                     class="d-none d-lg-block" />
                            </div>
                            <div :class="['-name', slide.title?.length > 30 ? '-long' : '']">
                                <div v-html="slide.title"></div>
                            </div>
                        </div>
                        <div v-else-if="isArticleDestinationSlideType(slide.slideType)"
                             class="-featured">
                            <div>
                                <span v-if="slide.slideType === 'article' && slide.sponsoredText"
                                      class="-sponsored-flag"
                                      v-html="slide.sponsoredText"></span>
                            </div>
                            <div class="d-none d-lg-block -type"
                                 v-html="getSlideTabText(slide.slideType, slide.title)"></div>
                            <div :class="['-headline', slide.title?.length > 50 ? '-long' : '']"
                                 v-html="slide.title"></div>
                        </div>
                        <template v-else>
                            <div v-if="slide.logoUrl" class="-logo">
                                <img :src="slide.logoUrl" alt="Logo" />
                            </div>
                        </template>
                    </div>
                    <img :src="slide.imageUrl" :class="['img-fit--cover', getImageCropFocusClass(slide.imageCropFocus)]" :alt="slide.title" width="100%" />
                    <div class="-nav context-dark weglot-exclude">
                        <div v-for="(allSlides, allIndex) in slides"
                             :key="allIndex"
                             :class="['-title d-none d-lg-block text-decoration-none', allIndex === index ? '-current' : '']"
                             @click.prevent.stop="instance.go(allIndex)">
                            <span v-html="getSlideTabText(allSlides.slideType, allSlides.title)"></span>
                        </div>
                        <div v-if="isArticleDestinationSlideType(slide.slideType)" class="-title d-lg-none" v-html="getSlideTabText(slide.slideType, slide.title)"></div>
                    </div>
                </a>
            </div>
        </template>
    </CarouselSliderComponent>
</template>

<script setup lang="ts">
    import { Options } from "@splidejs/vue-splide";
    import CarouselSliderComponent from "components/shared/carousel-slider.vue";
    import { CarouselSlideType } from "interfaces/types/app-types";
    import { CarouselSlide } from "interfaces/carousel";
    import { getImageCropFocusClass } from "services/helpers/images";
    import { shuffleInPlace } from "services/helpers/sort-helpers";
    import { PropType } from "vue";

    const props = defineProps({
        randomize: {
            type: Boolean,
            default: false
        },
        slides: {
            type: Array as PropType<CarouselSlide[]>,
            default: undefined
        }
    });

    const slideAdViewTracker = new Set<number>();
    const carouselConfig: Options = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pagination: false,
        type: "fade",
        rewind: true,
        breakpoints: 
            {
                992: {
                    pagination: true
                }
            }
    };

    const isArticleDestinationSlideType = (slideType: CarouselSlideType): boolean => { return slideType === "article" || slideType === "destination"; };

    function getSlideTabText(slideType: CarouselSlideType, slideTitle: string): string {
        let featuredSlideTitle = slideTitle;

        if (slideType === "article") {
            featuredSlideTitle = "Featured Article";
        } else if (slideType === "destination") {
            featuredSlideTitle = "Featured Destination";
        }
        return featuredSlideTitle;
    };

    function logAdView(slideIndex: number): void {
        const slide = props.slides[slideIndex];

        if (slide.broadstreetAdId && !slideAdViewTracker.has(slideIndex)) {
            const campaignSuffix = slide.broadstreetCampaignId ? `/c${slide.broadstreetCampaignId}` : "";
            const bsaImg = document.createElement("img");

            bsaImg.src = `https://ad.broadstreetads.com/display/${slide.broadstreetAdId}${campaignSuffix}?ts=${Date.now()}`;
            bsaImg.style.display = "none";
            document.body.appendChild(bsaImg);
            slideAdViewTracker.add(slideIndex); // Mark slide as seen
        }
    };

    if (props.randomize) {
        shuffleInPlace(props.slides);
    }
</script>
